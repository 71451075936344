.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  /* border: 1px solid #226672; */
  /* border-radius: 4px; */
  /* background: #226672; */
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button-pressed {
  box-shadow: inset 0px 0px 5px #c1c1c1 !important;
  outline: none !important;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  /* background: #277785;
  border-color: #277785; */
}






/* .button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
} */

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}