@import url(https://fonts.googleapis.com/css2?family=Sriracha&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sriracha&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@800&family=Poppins:wght@200&;500;800display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@800&family=Poppins:wght@200;300;800&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  /* height:100vh; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@font-face {
  font-family: 'Tenali';
  src: url(/static/media/TenaliRamakrishna-Regular.834f1463.ttf);
}

@font-face {
  font-family: 'Wendy One';
  font-display: block;
  src: url(/static/media/WendyOne-Regular.72675f99.ttf);
}

@font-face {
  font-family: 'Acme';
  src: url(/static/media/Acme-Regular.9f25ca8b.ttf);
  font-display: block;
}

@font-face {
  font-family: 'DidactGothic';
  src: url(/static/media/DidactGothic-Regular.e0c6f03d.ttf);
  font-display: block;
}

@font-face {
  font-family: 'Sansita';
  src: url(/static/media/Sansita-Regular.dc8ed2bf.ttf);
  font-display: block;
}

* {
  box-sizing: border-box;
}

.btn {
  color: #f0f0f0;
  margin: 10px 10px 10px 10px;
  font-size: 20px;
  text-decoration: none;
  transition: background-color 1s, background-image 1s, box-shadow 1s;
  background: #609285;
  border: 1px solid #522b52;
  box-shadow: 2px 2px 5px #3f2828;
  border-radius: 50px;
  padding: 10px 50px;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  text-align: center;
}

@media (max-width: 600px) {
  .btn {
    font-size: 15px;
    padding: 10px 30px;
  }
}

.btn:hover {
  cursor: pointer;
  background-color: #0dc795;
  box-shadow: 5px 4px 10px #2b0d0d;
  border-color: #609285;
  color: white;
}

.btn:disabled, .btn:hover:disabled, .btn:active:disabled {
  background-color: #9ac5baa6;
  box-shadow: none;
  background-image: none;
  border: 1px solid transparent;
  color: #f0f0f0;
  cursor: unset;
}

.btn.sec {
  background: transparent;
  color: #609285;
  border: 1px solid #609285;
  box-shadow: 1px 1px 3px rgba(63, 40, 63, 0.678);
}

.btn.sec:hover, .btn.sec:active {
  color: white;
  background: #b3a0b3;
}

.btn.small {
  font-size: 13px;
  padding: 7px 17px;
}

.btn-link {
  text-decoration: none;
  margin: 10px;
}

.btn-link .btn {
  margin: 0;
}

.btn-or {
  display: none;
  width: 100%;
  max-width: 300px;
  margin-top: 12px;
  border-top: 1px solid #a8cac2;
}

@media (max-width: 750px) {
  .btn-or {
    display: flex;
  }
}

.btn-or p {
  color: #609285;
  width: 50px;
  background: white;
  margin: -13px auto 0;
}

.button--danger {
  background: #522b52;
}

.button--danger:hover,
.button--danger:active {
  background: #693a69;
  border-color: #693a69;
}

h1 {
  position: relative;
  font-size: 45px;
  text-align: left;
  font-family: 'Sansita', sans-serif;
  font-weight: bold;
  color: #405852;
  padding: 10px 20px 10px;
  margin: 0;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 40px;
  }
}

@media (max-width: 950px) {
  h1 {
    font-size: 27px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 23px;
    padding: 0 0 14px 15px;
  }
}

h1 a {
  margin-right: 15px;
}

@media (max-width: 500px) {
  .premade-ws h1 {
    padding: 0 0 14px;
  }
}

h2 {
  border-bottom: 3px solid #806a80;
  font-size: medium;
  font-family: 'Sansita';
  font-size: 50px;
  line-height: 87px;
  color: #609285;
  margin: 4px;
}

@media (max-width: 700px) {
  h2 {
    font-size: 35px;
    line-height: 38px;
  }
}

a {
  text-decoration: none;
  color: black;
}

a:focus {
  color: black;
}

.form-control {
  max-width: 100%;
  margin: 0.5rem 0 0;
  width: 320px;
  max-width: 100%;
}

.form-control:first-child {
  margin-top: 1rem;
}

.form-control label, .form-control input, .form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  color: #522b52;
}

.form-control p {
  text-align: left;
}

.form-control input, .form-control textarea {
  width: 100%;
  height: 50px;
  font: inherit;
  border: 1px solid #609285;
  border-radius: 6px;
  height: 40px;
  background: white;
  padding: 0.15rem 0.25rem;
  padding-left: 20px;
}

.form-control input:-webkit-autofill, .form-control input -webkit-autofill:hover, .form-control input -webkit-autofill:focus, .form-control input -webkit-autofill:active, .form-control textarea:-webkit-autofill, .form-control textarea -webkit-autofill:hover, .form-control textarea -webkit-autofill:focus, .form-control textarea -webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.form-control input:focus, .form-control textarea:focus {
  outline: none;
  background: #eae2ea;
  border-color: #522b52;
}

.form-control p {
  height: 15px;
  width: 360px;
  padding: 10px 0;
  margin: 0;
}

.form-control.invalid label, .form-control.invalid p {
  color: #522b52;
}

.form-control.invalid input, .form-control.invalid textarea {
  border-color: #522b52;
  background: #eae2ea;
}

.popover-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.popover-container img {
  width: 20px;
  height: 20px;
}

.popover-container img:hover + .popover {
  opacity: 1;
  z-index: 6;
}

.popover-container .popover {
  position: absolute;
  left: 0;
  background: white;
  border: 1px solid #0dc795;
  box-shadow: 1px 1px 7px #6a7c7870;
  border-radius: 10px;
  padding: 10px;
  z-index: -1;
  width: 235px;
  opacity: 0;
  background: white;
  transition: all 0.5s ease-out;
  transition-delay: 0.2s;
}

.popover-container .popover:hover {
  opacity: 0;
  cursor: default;
}

:root {
  /* --main-color: rgb(62, 158, 190); */
  --main-color: #803F87;
  --main-color-light:rgb(128, 106, 128);
  --main-color-dark: rgb(56, 22, 56);
  /* --sec-color: #922727; */
  /* --sec-color: #609285;
  --sec-color-dark: #405852;
  --sec-color-light:#dceae7;
  --sec-color-bright: #0dc795;
  --sec-color-disabled: #9ac5baa6; */
  --sec-color: #1a92cc;
  --sec-color-dark: #292f4e;
  --sec-color-light: #dcdfea;
  --sec-color-xtra-light:#dceae7;
  --sec-color-bright: #0d2cc7;
  --sec-color-disabled: #9f9ac5a6;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.worksheet {
  page-break-after: always;
}

.main {
  background-color: #522b52;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.main ::-webkit-scrollbar {
  width: 11px;
}

.main ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #522b52;
  border-radius: 5px;
}

.main ::-webkit-scrollbar-thumb {
  background: #784d78;
  border-radius: 5px;
}

.main ::-webkit-scrollbar-thumb:hover {
  background: #b3a0b3;
}

@media (max-width: 1400px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
}

.ac {
  margin: 0px 0px 0px 20px;
}

/* Dropdown Button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  left: 180px;
  top: 0px;
  background-color: #f1f1f1;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  left: 500px;
}

/* Change color of dropdown links on hover */
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.mobile-warning {
  min-height: 100px;
  position: fixed;
  top: 80px;
  z-index: 50;
  padding: 39px 93px;
  font-size: 25px;
  background: #421358ed;
  font-family: 'Sansita';
  text-align: center;
  color: white;
}

@media (max-width: 500px) {
  .mobile-warning {
    font-size: 20px;
    padding: 40px 20px;
  }
}

.mobile-warning div {
  position: absolute;
  top: 10px;
  font-family: 'Acme';
  right: 20px;
  cursor: pointer;
}

.iframe-wrapper {
  background-color: white;
  width: 100%;
  height: 800px;
  position: relative;
  border: 4px solid grey;
}

.iframe-wrapper a {
  margin-top: 10px;
  display: block;
}

.iframe-wrapper a img {
  width: 20px;
}

.iframe-wrapper .mobile-reminder {
  margin: 9px 5px;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  background-color: #dceae7;
}

.iframe-wrapper .pdf-viewer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.iframe-wrapper .pdf-viewer-wrap .page-turn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 70px);
  width: 100%;
}

.iframe-wrapper .pdf-viewer-wrap .document-load-wrap {
  height: 100%;
  position: relative;
}

.iframe-wrapper .pdf-viewer-wrap .document-load-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  aspect-ratio: 1.416829;
  z-index: 1;
  opacity: 0;
}

.iframe-wrapper .pdf-viewer-wrap .ws-error {
  display: flex;
  flex-direction: column;
  padding: 50px;
  font-size: 20px;
}

.iframe-wrapper .pdf-viewer-wrap .ws-error .title {
  font-size: 30px;
  font-weight: bold;
  color: #522b52;
  text-align: center;
}

.iframe-wrapper .pdf-viewer-wrap .ws-error .generic {
  padding-bottom: 20px;
}

.iframe-wrapper .pdf-viewer-wrap .ws-error .specific {
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards 0.3s;
  padding-bottom: 20px;
}

.iframe-wrapper .pdf-viewer-wrap .ws-error .loading-container {
  margin: auto;
}

.iframe-wrapper .prev-btn, .iframe-wrapper .next-btn {
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;
  border-bottom: 10px solid #405852;
  border-right: 10px solid #405852;
  margin: 10px;
}

.iframe-wrapper .prev-btn:hover, .iframe-wrapper .next-btn:hover {
  border-color: #609285;
  cursor: pointer;
}

.iframe-wrapper .prev-btn:disabled, .iframe-wrapper .next-btn:disabled {
  border-color: lightgray;
  cursor: unset;
}

.iframe-wrapper .prev-btn {
  rotate: 135deg;
}

.iframe-wrapper .next-btn {
  rotate: -45deg;
}

.iframe-wrapper .page-number-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe-wrapper .page-number-display .prev-btn, .iframe-wrapper .page-number-display .next-btn {
  display: none;
}

.iframe-wrapper.horizontal-style {
  height: 620px;
}

.iframe-wrapper.horizontal-style .react-pdf__Page__svg svg {
  width: 100% !important;
}

@media (max-width: 700px) {
  .iframe-wrapper.horizontal-style {
    height: 530px;
  }
}

@media (max-width: 600px) {
  .iframe-wrapper.horizontal-style {
    height: 455px;
  }
}

@media (max-width: 550px) {
  .iframe-wrapper.horizontal-style {
    height: 670px;
  }
  .iframe-wrapper.horizontal-style .react-pdf__Page__svg {
    overflow: visible !important;
  }
  .iframe-wrapper.horizontal-style .react-pdf__Page__svg svg {
    max-width: 200%;
    width: 200% !important;
    max-height: calc(100% - 10px);
  }
}

@media (max-width: 400px) {
  .iframe-wrapper.horizontal-style {
    height: 570px;
  }
}

.iframe-wrapper.horizontal-style .page-turn-wrap {
  max-height: calc(100% - 70px);
}

@media (max-width: 900px) {
  .iframe-wrapper.horizontal-style .page-turn-wrap .prev-btn, .iframe-wrapper.horizontal-style .page-turn-wrap .next-btn {
    display: none;
  }
}

.iframe-wrapper.horizontal-style .page-turn-wrap .document-load-wrap {
  margin: auto;
  min-width: 300px;
  width: 100%;
}

@media (max-width: 550px) {
  .iframe-wrapper.horizontal-style .page-turn-wrap .document-load-wrap {
    overflow-y: auto;
  }
}

@media (max-width: 400px) {
  .iframe-wrapper.horizontal-style .page-turn-wrap .document-load-wrap {
    min-width: 100%;
  }
}

.iframe-wrapper.horizontal-style .page-turn-wrap .document-load-wrap img.horizontal-overlay {
  opacity: 0.5;
}

@media (max-width: 550px) {
  .iframe-wrapper.horizontal-style .page-turn-wrap .document-load-wrap img {
    width: 200%;
    min-width: 200%;
    max-height: calc(100% - 11px);
  }
}

@media (max-width: 900px) {
  .iframe-wrapper.horizontal-style .page-number-display .prev-btn, .iframe-wrapper.horizontal-style .page-number-display .next-btn {
    display: block;
  }
}

@media (max-width: 500px) {
  .iframe-wrapper.vertical-style {
    height: 720px;
  }
}

@media (max-width: 400px) {
  .iframe-wrapper.vertical-style {
    height: 620px;
  }
}

.iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap {
  justify-content: center;
}

@media (max-width: 700px) {
  .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap {
    height: calc(100% - 88px);
  }
  .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap .prev-btn, .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap .next-btn {
    display: none;
  }
}

@media (max-width: 540px) {
  .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap {
    aspect-ratio: 0.7;
    height: auto;
  }
}

.iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap .document-load-wrap {
  aspect-ratio: 0.7;
}

.iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap .document-load-wrap img.vertical-overlay {
  opacity: 0.5;
  height: calc(100% - 8px);
}

@media (max-width: 520px) {
  .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap .document-load-wrap img.vertical-overlay {
    height: calc(100% - 5px);
  }
}

@media (max-width: 400px) {
  .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-turn-wrap .document-load-wrap img.vertical-overlay {
    height: calc(100% - 4px);
  }
}

@media (max-width: 700px) {
  .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-number-display .prev-btn, .iframe-wrapper.vertical-style .pdf-viewer-wrap .page-number-display .next-btn {
    display: block;
  }
}

.react-pdf__Document {
  height: 100%;
}

.react-pdf__Page {
  height: 100%;
}

.react-pdf__Page .react-pdf__Page__svg {
  display: flex;
  opacity: 0;
  animation: fadeInPDF 0.3s ease-in-out forwards;
  max-width: 100%;
  height: 100% !important;
  width: auto !important;
  max-height: 100% !important;
}

.react-pdf__Page .react-pdf__Page__svg svg {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
  margin: auto;
}

.pdf-draft .react-pdf__Page .react-pdf__Page__svg {
  animation: fadeInPDF 0.5s ease-in-out forwards 0.3s;
}

.iframe-loading {
  position: absolute;
  z-index: 2;
  top: calc(50% - 50px);
  left: calc(50% - 150px);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iframe-loading .loading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe-loading .statements {
  width: 100%;
  position: relative;
}

.iframe-loading .statements .sm {
  animation: fadeInOut 8s ease-in-out;
  opacity: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  color: #522b52;
  font-family: 'Acme';
  font-size: 20px;
}

.iframe-loading .statements .sm-1 {
  animation-delay: 0;
}

.iframe-loading .statements .sm-2 {
  animation-delay: 8s;
}

.iframe-loading .statements .sm-3 {
  animation-delay: 16s;
}

.iframe-loading .statements .sm-4 {
  animation-delay: 24s;
}

.iframe-loading .statements .sm-5 {
  animation-delay: 32s;
}

.iframe-loading .statements .sm-6 {
  animation: fadeIn 8s ease-in forwards;
  animation-delay: 40s;
}

@keyframes fadeInOut {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  10% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  90% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20px);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  10% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInPDF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.general-main-container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 10px);
}

.home-container {
  padding: 0px;
}

@media (max-width: 800px) {
  .home-container {
    max-width: 100vw;
    width: 100vw;
  }
}

p {
  width: 100%;
  text-align: center;
  margin: 20px 20px 10px;
}

p span {
  color: #eae2ea;
}

.picture-background {
  background-color: black;
  background-image: linear-gradient(45deg, #283834, transparent);
  background-image: url(/static/media/artbg4sm2.30da4a9c.jpg);
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  max-width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.picture-background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: hue-rotate(163deg);
          backdrop-filter: hue-rotate(163deg);
  background-image: radial-gradient(#000000e0 36%, #4403604a 56%, #00000000);
}

@media (max-width: 800px) {
  .picture-background:after {
    background-image: radial-gradient(#000000e0 49%, #4403604a 88%, #00000000);
  }
}

@media (min-width: 1600px) {
  .picture-background {
    background-image: url(/static/media/artbg4.fcc2f693.jpg);
  }
}

@media (max-width: 800px) {
  .picture-background {
    width: 100%;
    max-width: 100%;
    background-image: url(/static/media/artbg4mobile.3e460e60.png);
  }
}

.picture-background .title-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-top: 120px;
}

.picture-background .title-banner .img-wrap {
  width: 500px;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picture-background .title-banner img.logo {
  width: 400px;
  max-width: 100%;
  margin-right: 0;
}

@media (max-width: 850px) {
  .picture-background .title-banner img.logo {
    max-width: 80vw;
  }
}

.picture-background .title-banner h2 {
  text-align: center;
  font-family: "Exo";
  border-bottom: none;
  font-size: 2rem;
}

.picture-background .title-banner p {
  text-align: center;
  padding: 10px;
  text-shadow: none;
  font-size: 30px;
  font-family: 'Sansita';
  color: white;
  width: auto;
  padding: 20px 20px 0;
  margin: 20px 0;
}

@media (max-width: 750px) {
  .picture-background .title-banner p {
    font-size: 24px;
  }
}

.picture-background .title-banner p.ws-free {
  color: #dceae7;
  padding-top: 0;
}

.picture-background .title-banner .logo-img-container {
  display: flex;
  align-items: center;
  height: 200px;
  width: 100%;
  max-width: 1500px;
  margin: 50px;
  justify-content: space-between;
}

.picture-background .title-banner .logo-img-container .worksheet {
  z-index: 7;
  transform: rotate(-17deg);
  width: 205px;
  max-width: 60%;
}

@media (max-width: 850px) {
  .picture-background .title-banner .logo-img-container .worksheet {
    display: none;
  }
}

.picture-background .title-banner .logo-img-container .task-cards {
  width: 400px;
  max-width: 89%;
  z-index: 7;
}

@media (max-width: 850px) {
  .picture-background .title-banner .logo-img-container .task-cards {
    display: none;
  }
}

.picture-background .buttons-wrapper {
  display: flex;
  justify-content: space-between;
  width: 1500px;
  max-width: 100%;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.picture-background .buttons-wrapper .or {
  color: white;
  font-size: 20px;
  z-index: 1;
}

.picture-background .button-container {
  max-width: 100%;
  position: relative;
  padding: 20px;
  margin: 0 30px;
  font-size: 24px;
  font-family: 'Sansita';
  color: white;
  z-index: 5;
}

.picture-background .button-container .btn {
  width: 250px;
  max-width: 100%;
  justify-content: center;
  padding: 10px 20px;
  margin: 0 auto 0;
}

.picture-background .button-container p {
  text-align: left;
}

.bold {
  font-family: "Exo", sans-serif;
  font-size: 3.7rem;
}

.bold.MB {
  color: #b122ec;
}

.bold.MD {
  color: #381638;
}

.bold.SB {
  color: #0dc795;
}

.bold.SD {
  color: #405852;
}

@media (max-width: 1400px) {
  .bold {
    font-size: 2.5rem;
  }
}

@media (max-width: 800px) {
  .bold {
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .bold {
    font-size: 1.7rem;
  }
}

.section-container {
  display: flex;
  padding: 4%;
  padding-bottom: 4%;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 2.7rem;
  background-color: #609285;
  background-image: linear-gradient(50deg, #405852, #609285, #dceae7);
  min-height: 82vh;
  max-width: 100vw;
  padding-bottom: 150px;
}

@media (max-width: 1400px) {
  .section-container {
    font-size: 2rem;
  }
}

@media (max-width: 1200px) {
  .section-container {
    font-size: 1.9rem;
  }
}

@media (max-width: 800px) {
  .section-container {
    flex-direction: column;
    font-size: 1.7rem;
    min-height: 43vh;
    min-height: 500px;
  }
}

.section-container p {
  color: white;
  padding: 5px;
}

@media (max-width: 800px) {
  .section-container p {
    padding-top: 20px;
    font-size: 1.5rem;
    width: auto;
  }
}

.section-container.engage {
  background-image: linear-gradient(150deg, #806a80, #522b52, #381638);
  align-items: center;
}

.section-container.engage .description-container {
  width: 55%;
}

@media (max-width: 900px) {
  .section-container.engage .description-container {
    width: 100%;
  }
}

.section-container.engage .ex-container {
  width: 730px;
  height: 300px;
}

@media (max-width: 1200px) {
  .section-container.engage .ex-container {
    width: 400px;
  }
}

@media (max-width: 900px) {
  .section-container.engage .ex-container {
    width: 100%;
    min-width: unset;
    min-width: 100px;
    width: 90vw;
    max-width: 100vw;
  }
}

.section-container.engage .ex2 {
  top: 22%;
  left: unset;
  height: 450px;
  width: auto;
  right: 0;
  box-shadow: none;
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

@media (max-width: 1200px) {
  .section-container.engage .ex2 {
    height: 250px;
  }
}

.section-container.engage .ex3 {
  display: none;
}

.section-container.spiral {
  min-height: 23vh;
  padding-bottom: 65px;
}

.section-container.spiral .description-container {
  width: 75%;
}

@media (max-width: 900px) {
  .section-container.spiral .description-container {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .section-container.spiral .ex-container {
    display: none;
  }
}

@media (max-width: 1400px) {
  .section-container.differentiate {
    padding-bottom: 70px;
  }
}

@media (max-width: 800px) {
  .section-container.differentiate {
    padding-bottom: 2%;
  }
  .section-container.differentiate .ex {
    display: none;
  }
}

.description-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .description-container {
    width: 100%;
  }
}

.ex-container {
  position: relative;
  min-width: 600px;
}

@media (max-width: 1400px) {
  .ex-container {
    min-width: 540px;
    width: 540px;
  }
}

@media (max-width: 1200px) {
  .ex-container {
    width: 400px;
    min-width: 400px;
  }
}

@media (max-width: 800px) {
  .ex-container {
    width: 100%;
    min-width: auto;
  }
}

.ex {
  box-shadow: 2px 3px 5px 2px #363434;
  position: absolute;
  width: 350px;
  height: 500px;
}

@media (max-width: 1400px) {
  .ex {
    width: 275px;
    height: 375px;
  }
}

@media (max-width: 1200px) {
  .ex {
    width: 200px;
    height: 273px;
  }
}

.ex.ex1 {
  z-index: 1;
  top: 0;
  left: 100px;
}

@media (max-width: 800px) {
  .ex.ex1 {
    left: 2%;
  }
}

.ex.ex2 {
  z-index: 2;
  top: 100px;
  left: 177px;
}

@media (max-width: 1400px) {
  .ex.ex2 {
    top: 60px;
  }
}

@media (max-width: 1200px) {
  .ex.ex2 {
    left: 155px;
  }
}

@media (max-width: 800px) {
  .ex.ex2 {
    left: 55px;
    left: 18%;
  }
}

.ex.ex3 {
  z-index: 3;
  top: 200px;
  right: 0;
}

@media (max-width: 1400px) {
  .ex.ex3 {
    top: 120px;
  }
}

@media (max-width: 800px) {
  .ex.ex3 {
    left: 105px;
    left: 34%;
  }
}

.ex.ex4 {
  z-index: 4;
  top: 17%;
  left: 400px;
}

@media (max-width: 800px) {
  .ex.ex4 {
    left: 160px;
    left: 50%;
  }
}

.concept-custom {
  width: 70%;
}

@media (max-width: 1400px) {
  .concept-custom {
    height: 180px;
    width: 290px;
  }
}

.infinite.section-container {
  background-color: white;
  background-image: none;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  height: 400px;
}

@media (max-width: 1000px) {
  .infinite.section-container {
    font-size: 2rem;
  }
}

.infinite.section-container p {
  padding: 5px;
  color: black;
}

.infinite .description-container {
  width: 70%;
}

@media (max-width: 800px) {
  .infinite .description-container {
    width: 95%;
  }
}

.teacher.section-container {
  display: flex;
  padding: 2%;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  background-color: #609285;
  background-image: linear-gradient(150deg, #806a80, #522b52, #381638);
  height: 450px;
  padding-bottom: 50px;
}

@media (max-width: 1400px) {
  .teacher.section-container {
    height: 60vh;
  }
}

@media (max-width: 1000px) {
  .teacher.section-container {
    font-size: 2rem;
  }
}

@media (max-width: 800px) {
  .teacher.section-container {
    height: 50vh;
    height: 500px;
    justify-content: flex-start;
  }
}

.teacher.section-container p {
  color: white;
  padding: 5px;
}

.teacher.section-container .teacher-description-container {
  width: 40vw;
  margin-right: 20px;
}

@media (max-width: 800px) {
  .teacher.section-container .teacher-description-container {
    width: 100vw;
  }
}

.teacher.section-container .teacher-ex-container {
  position: relative;
  width: 45vw;
  height: 100%;
}

@media (max-width: 1200px) {
  .teacher.section-container .teacher-ex-container {
    width: 400px;
  }
}

@media (max-width: 800px) {
  .teacher.section-container .teacher-ex-container {
    width: 100vw;
  }
}

.teacher.section-container .teacher-ex-container .teacher-ex {
  height: 80%;
  box-shadow: 2px 3px 7px 2px #363535;
  position: absolute;
}

@media (max-width: 1200px) {
  .teacher.section-container .teacher-ex-container .teacher-ex {
    width: 200px;
    height: 300px;
  }
}

.main {
  width: 100%;
}

.general-main-container {
  display: flex;
  flex-direction: column;
}

/*Home */
/* Shared */
.signup {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.main-general-container {
  display: flex;
  flex-direction: column;
  background-color: #609285;
}

@media (max-width: 800px) {
  .main-general-container {
    width: 100%;
    max-width: 100%;
  }
}

/* Parents/Teachers/Schools */
.header-wrapper {
  position: relative;
}

@media (max-width: 900px) {
  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.header {
  background-color: #806a80;
  color: white;
  height: 60vh;
  margin: 62px 0px 0px 0px;
  padding: 0 15%;
}

@media (max-width: 1300px) {
  .header {
    color: white;
    height: auto;
    max-width: 100%;
    padding: 0 10%;
  }
}

@media (max-width: 900px) {
  .header {
    padding: 0 15%;
  }
}

@media (max-width: 800px) {
  .header {
    max-width: 100%;
    margin: 55px 0px 0px 0px;
    height: auto;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 0;
  }
}

.header h1 {
  font-size: 40px;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

@media (max-width: 1350px) {
  .header h1 {
    font-size: 30px;
  }
}

@media (max-width: 800px) {
  .header h1 {
    font-size: 25px;
    font-weight: 500;
  }
}

.header p {
  font-size: 30px;
  color: #c5b3b3;
}

@media (max-width: 1350px) {
  .header p {
    font-size: 24px;
  }
}

@media (max-width: 600px) {
  .header p {
    font-size: 20px;
  }
}

.header img {
  width: 400px;
  max-width: 40%;
  float: right;
}

@media (max-width: 1300px) {
  .header img {
    width: 250px;
  }
}

@media (max-width: 800px) {
  .header img {
    width: 150px;
  }
}

.header .one-free {
  font-size: 18px;
  padding: 5px;
  margin: 0;
}

.header-center {
  background-color: #381638;
  height: 100%;
  padding: 30px 70px;
  display: flex;
  max-width: 1300px;
  margin: auto;
}

@media (max-width: 1600px) {
  .header-center {
    padding: 30px 50px;
  }
}

@media (max-width: 1350px) {
  .header-center {
    padding: 30px 50px;
  }
}

@media (max-width: 800px) {
  .header-center {
    padding: 20px;
  }
}

.schoolex1 {
  z-index: 2;
  top: 5%;
  left: 10px;
}

@media (max-width: 800px) {
  .schoolex1 {
    left: 200px;
    left: 15%;
  }
}

.schoolex2 {
  z-index: 3;
  top: 15%;
  left: 180px;
  left: 20%;
}

@media (max-width: 800px) {
  .schoolex2 {
    left: 37%;
  }
}

.schoolex3 {
  z-index: 1;
  top: 5%;
  left: 350px;
  left: 40%;
}

@media (max-width: 800px) {
  .schoolex3 {
    left: 52%;
  }
  .schoolex3.teacher-ex {
    display: none;
  }
}

@media (max-width: 900px) {
  .welcome-modal {
    width: 90%;
  }
}

.welcome-modal .continue {
  color: #609285;
  margin: 0;
}

.welcome-modal .sec {
  background: #522b52;
  color: white;
}

.welcome-modal .modal-header h2 {
  line-height: 67px;
}

.welcome-modal .modal-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-family: 'Sansita';
  font-weight: bold;
  text-align: center;
}

@media (max-width: 750px) {
  .welcome-modal .modal-header-container {
    font-size: 35px;
    line-height: 32px;
    padding-bottom: 17px;
  }
}

.welcome-modal .modal-header-container img {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}

@media (max-width: 750px) {
  .welcome-modal .modal-header-container img {
    width: 20px;
    height: 20px;
  }
}

.welcome-modal .modal-header-container img.right {
  margin-right: 0;
  margin-left: 20px;
}

.welcome-modal .welcome-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-modal .welcome-modal-content img {
  width: 80%;
  max-width: 400px;
}

.welcome-modal .welcome-modal-content .msg {
  font-size: 30px;
  text-align: center;
  padding: 10px 40px;
}

@media (max-width: 750px) {
  .welcome-modal .welcome-modal-content .msg {
    font-size: 24px;
    padding: 10px 20px;
  }
}

@media (max-width: 600px) {
  .welcome-modal .welcome-modal-content .msg {
    font-size: 18px;
  }
}

.welcome-modal .welcome-modal-content .msg span {
  font-family: 'Sansita';
  color: #405852;
  font-weight: bold;
}

.welcome-modal .welcome-modal-content .msg span.free {
  color: #522b52;
}

.welcome-modal .welcome-modal-footer {
  align-items: flex-end;
}

@media (max-width: 750px) {
  .welcome-modal .welcome-modal-footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.info {
  min-width: 1170px;
}

@media (max-width: 1300px) {
  .info {
    min-width: 100vw;
  }
}

.info .content {
  display: flex;
  flex-direction: column;
  max-width: 90vw;
}

.info .content .content-title {
  margin-bottom: 20px;
}

.info .content .content-title h2 {
  padding-bottom: 30px;
}

@media (max-width: 800px) {
  .info .content .content-title h2 {
    padding-bottom: 10px;
    text-align: center;
  }
}

.info .content .content-title .subtitle {
  font-family: 'Tenali';
  font-size: 30px;
  color: #405852;
  margin-top: -44px;
  margin-left: 50px;
}

@media (max-width: 800px) {
  .info .content .content-title .subtitle {
    margin-top: 3px;
    margin-left: 25px;
    font-size: 20px;
    line-height: 20px;
  }
}

.info .content p {
  text-align: left;
}

.info.new span {
  color: #522b52;
  font-weight: bold;
}

.info.samples .content {
  width: 100%;
}

.info.samples .content h2 {
  padding-bottom: 0px;
}

.info.samples .content h3 {
  text-align: center;
  font-size: 30px;
  color: #405852;
  font-family: "Acme";
}

.info.samples .content h3.ws-free {
  margin: 0 0 10px;
}

.info.samples .content h3.ws-free img {
  width: 20px;
}

.info.samples .content .blurb {
  text-align: center;
  font-size: 25px;
  color: #522b52;
  font-family: 'Acme';
  font-weight: bold;
}

.info.samples .content .worksheets-group {
  display: flex;
  justify-content: center;
}

.info.samples .content .subtitle {
  text-align: center;
  font-size: 20px;
  padding: 50px 20px 20px;
  border-bottom: 2px solid #dceae7;
}

.info.samples .sample-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.info.samples .sample {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  max-width: 100%;
}

.info.samples .sample .title-container {
  justify-content: center;
  border-bottom: 0;
  padding: 0;
}

.info.samples .sample .title-container h3 {
  display: flex;
  flex-direction: column;
  font-family: 'Wendy One';
  margin: 10px;
}

@media (max-width: 600px) {
  .info.samples .sample .title-container h3 {
    font-size: 23px;
    text-align: center;
  }
}

.info.samples .sample .title-container h3 div {
  font-size: 25px;
  color: #522b52;
}

@media (max-width: 600px) {
  .info.samples .sample .title-container h3 div {
    font-size: 20px;
  }
}

.info.samples .sample .title-container img {
  margin-left: 5px;
  padding: 5px;
  height: 40px;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.info.samples .sample .title-container img:hover {
  opacity: 0.6;
  cursor: pointer;
}

.info.samples .sample p {
  width: 430px;
  max-width: 100%;
  text-align: center;
  margin-top: 0;
}

.info.samples .sample .preview {
  width: 280px;
  height: 340px;
  max-width: 100%;
}

.info.samples .sample .preview.hz {
  width: 380px;
  height: auto;
}

.info .faq-qa .faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #eae2ea;
  border-radius: 5px;
  font-size: 24px;
  line-height: 1em;
  color: #381638;
  font-family: 'Tenali';
  font-weight: bold;
  margin-bottom: 20px;
  padding: 5px 40px;
  cursor: pointer;
}

@media (max-width: 850px) {
  .info .faq-qa .faq-question {
    font-size: 18px;
    padding: 15px 30px;
  }
}

@media (max-width: 550px) {
  .info .faq-qa .faq-question {
    padding: 15px 25px;
  }
}

@media (max-width: 350px) {
  .info .faq-qa .faq-question {
    font-size: 13px;
  }
}

.info .faq-qa .faq-question:last-of-type {
  margin-bottom: 0;
}

.info .faq-qa .faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.info .faq-qa .faq-answer p {
  font-size: 18px;
  padding: 10px 60px 30px;
  margin: 0;
  text-align: left;
  font-family: 'DidactGothic';
}

@media (max-width: 850px) {
  .info .faq-qa .faq-answer p {
    font-size: 18px;
    padding: 10px 50px 30px;
  }
}

@media (max-width: 550px) {
  .info .faq-qa .faq-answer p {
    font-size: 15px;
    padding: 10px 40px 30px;
  }
}

@media (max-width: 450px) {
  .info .faq-qa .faq-answer p {
    font-size: 14px;
    padding: 0px 20px 20px;
  }
}

@media (max-width: 350px) {
  .info .faq-qa .faq-answer p {
    font-size: 13px;
  }
}

.info .faq-qa .faq-answer p a {
  color: #609285;
  border-bottom: 1px solid #dceae7;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.info .faq-qa .faq-answer p a:hover {
  opacity: 0.7;
  border-bottom-color: #405852;
}

.info .faq-qa svg {
  transition: transform 0.3s ease-out;
  min-width: 28px;
  max-width: 28px;
  margin-left: 10px;
}

@media (max-width: 850px) {
  .info .faq-qa svg {
    min-width: 30px;
    height: 30px;
    max-width: 30px;
  }
}

.info .faq-qa.active svg {
  transform: rotate(-90deg);
}

.info .faq-qa.active .faq-answer {
  max-height: 400px;
}

@media (max-width: 700px) {
  .info .faq-qa.active .faq-answer {
    max-height: 600px;
  }
}

.info.terms-of-service li {
  padding: 40px 40px 0;
  font-family: "Tenali";
  font-size: 25px;
}

@media (max-width: 600px) {
  .info.terms-of-service li {
    line-height: 30px;
  }
}

.info.terms-of-service .more-info {
  padding: 40px;
}

@media (max-width: 700px) {
  .info.terms-of-service .more-info {
    padding: 10px 0;
    text-align: center;
  }
}

.info.terms-of-service a {
  color: #609285;
  border-bottom: 1px solid #dceae7;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.info.terms-of-service a:hover {
  opacity: 0.7;
  border-bottom-color: #405852;
}

.info.coming-soon .cs-title {
  font-size: 25px;
  font-weight: bold;
  color: #381638;
  margin-bottom: 10px;
}

.info.coming-soon .img-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.info.coming-soon .img-group .vertical {
  width: 300px;
  height: 400px;
}

.info.coming-soon .img-group .horizontal {
  width: 400px;
  max-width: 90%;
}

.info.coming-soon .p-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.info.coming-soon .description {
  margin: 10px 40px;
  font-size: 20px;
  padding-left: 70px;
  max-width: 100%;
}

@media (max-width: 600px) {
  .info.coming-soon .description {
    padding-left: 30px;
  }
}

.info.privacy-policy p {
  width: auto;
  margin: 10px 20px;
}

.info.privacy-policy ul {
  margin-top: 0;
}

.info.privacy-policy li {
  margin: 10px 0;
}

.info.privacy-policy li p {
  margin: 0 20px 0 10px;
}

.info.privacy-policy h1 {
  width: 100%;
  margin: 10px 0;
}

.info.privacy-policy h2 {
  font-size: 34px;
  line-height: 50px;
}

.info.privacy-policy h3 {
  margin-bottom: 0;
}

.info.privacy-policy h4 {
  color: #609285;
  margin-bottom: 0;
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  width: 100%;
  color: white;
  height: 170px;
  position: absolute;
  /*fixed= stays at bottom but scrolls up? relative= stays away from bottom*/
  top: 100%;
  overflow: hidden;
  margin: auto;
  background-color: #381638;
}

@media (max-width: 750px) {
  .footer {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
  }
}

.footer .title {
  font-size: 17px;
  font-family: 'Acme';
  letter-spacing: 1px;
}

.footer .link {
  text-decoration: none;
  color: #a8cac2;
  opacity: 0.7;
  margin: 5px;
  transition: opacity 0.5s ease-out;
  border-bottom: 1px solid transparent;
}

.footer .link:hover {
  opacity: 1;
  border-bottom: 1px solid #dceae7;
}

.footer .email {
  color: #a8cac2;
  opacity: 0.7;
  margin: 5px;
  border-bottom: 1px solid transparent;
}

.footer .link-group, .footer .group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 50px;
}

@media (max-width: 1000px) {
  .footer .link-group, .footer .group {
    padding: 20px;
  }
}

.main-header {
  width: 100%;
  max-width: 100vw;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  background: #fdfdfd;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 40;
}

.main-header .sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 10px 0 auto;
}

@media (max-width: 500px) {
  .main-header .sign-in {
    margin: 0 5px 0 auto;
  }
}

.main-header .sign-in a.signup {
  margin-right: 20px;
  padding: 0;
}

@media (max-width: 500px) {
  .main-header .sign-in a.signup {
    display: none;
  }
}

main {
  margin-top: 5rem;
}

.drawer-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-btn {
  width: 50px;
  height: 35px;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  display: none;
}

@media (max-width: 700px) {
  .menu-btn {
    display: flex;
  }
}

@media (max-width: 1150px) {
  .menu-btn.not-logged {
    display: flex;
  }
}

.menu-btn span {
  display: block;
  width: 30px;
  min-height: 2px;
  border-radius: 2px;
  background: #522b52;
}

.mobile-1 img, .mobile-2 img {
  width: 21px;
  margin-right: 5px;
}

.header-nav {
  display: block;
}

@media (max-width: 1150px) {
  .header-nav .mobile-0 {
    display: none;
  }
}

@media (max-width: 700px) {
  .header-nav .mobile-1 {
    display: none;
  }
}

@media (max-width: 550px) {
  .header-nav .mobile-2 {
    display: none;
  }
}

@media (max-width: 400px) {
  .header-nav .mobile-3 {
    display: none;
  }
}

.menu-items {
  display: flex;
  align-items: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', 'sans-serif';
  font-size: 16px;
  color: black;
  text-align: center;
  background: transparent;
  border: none;
  text-decoration: none;
  padding: 0;
}

.menu-items:hover {
  cursor: pointer;
  background: #f3f3f3;
}

.menu-items img {
  width: 30px;
  margin-right: 10px;
}

.menu-items button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  width: 100%;
  padding: 12px 20px;
}

.menu-items button:hover {
  cursor: pointer;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
}

.nav-links .nav-link {
  border: 2px solid transparent;
  color: #292929;
  text-decoration: none;
  margin: 0 5px;
  position: relative;
}

.nav-links .nav-link .main-button {
  padding: 5px 5px 3px;
}

.nav-links .nav-link .main-link {
  padding: 5px 5px 10px;
  display: block;
}

.nav-links .nav-link .main-link button {
  padding: 0;
}

.nav-links .nav-link.in-progress {
  font-weight: bold;
  font-family: 'Sansita';
}

.nav-links .nav-link.in-progress img {
  width: 20px;
  margin-right: 5px;
}

.nav-links .nav-link.in-progress button {
  color: #803f87;
}

.nav-links .nav-link.in-progress.active {
  border-bottom: 2px solid #b3a0b3;
}

.nav-links .nav-link.in-progress .main-link {
  padding: 0;
  display: inline-block;
}

.nav-links .nav-link.in-progress .main-link button {
  padding: 5px 5px 10px;
}

@media (max-width: 1000px) {
  .nav-links .nav-link {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .nav-links .nav-link {
    color: black;
    text-decoration: none;
  }
}

.nav-links .nav-link:hover, .nav-links .nav-link.active {
  cursor: pointer;
  background: transparent;
  color: #292929;
  border-bottom: 2px solid #b3a0b3;
}

.nav-links .nav-link.active.new-ws-ip {
  border-bottom: 2px solid transparent;
}

.nav-links .nav-link .menu-options {
  position: absolute;
  top: calc(100% + 2px);
  opacity: 0;
  z-index: -1;
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 20px 0 0;
  box-shadow: 0 1px 3px #c2c2c2;
  border-radius: 5px;
  transition: opacity 0.2s ease-in-out;
  display: none;
}

.nav-links .nav-link .menu-options .menu-section-title {
  color: #522b52;
  padding: 0 30px;
  font-size: 16px;
  border-bottom: 1px solid #dceae7;
}

.nav-links .nav-link.new-worksheet .new-assign-btn {
  padding-bottom: 10px;
}

.nav-links .nav-link.new-worksheet .new-assign-btn:hover {
  cursor: default;
}

.nav-links .nav-link.new-worksheet:hover .menu-options.active {
  opacity: 1;
  z-index: 5;
  display: block;
}

@media (hover: none) {
  .nav-links .nav-link.new-worksheet:hover .menu-options.active {
    opacity: 0;
    z-index: 0;
    display: none;
  }
}

.nav-links .nav-link.new-worksheet .menu-options.active.clicked {
  opacity: 1;
  z-index: 5;
  display: block;
}

.nav-links .main-button {
  border: none;
  color: #292929;
  background: transparent;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.nav-links .main-button:focus {
  outline: none;
}

.logo {
  background-color: transparent;
  margin-right: 20px;
}

.logo:hover {
  background-color: transparent;
}

.logo img {
  height: 60px;
}

@media (max-width: 700px) {
  .logo img {
    height: 50px;
  }
}

@media (max-width: 950px) {
  .logo.mobile-hidden {
    display: none;
  }
}

.purchase-tokens-header {
  padding-bottom: 0 !important;
}

.purchase-tokens-wrapper {
  padding-top: 0 !important;
}

.side-drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 42%;
  min-width: 200px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.side-drawer .nav-links {
  height: auto;
  flex-direction: column-reverse;
  align-items: center;
}

.side-drawer .nav-links .nav-link {
  margin: 20px 0.8rem;
}

.user-btn {
  position: absolute;
  right: 15px;
  display: flex;
  margin-left: auto;
  cursor: pointer;
}

@media (max-width: 820px) {
  .user-btn {
    position: unset;
    align-items: center;
  }
}

.user-btn .token-btn {
  -ms-grid-row-align: center;
  align-self: center;
  padding: 20px 10px;
}

.user-btn button {
  border: none;
  background: transparent;
}

.user-btn button:hover {
  cursor: pointer;
}

.scrim {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.user-icon {
  width: 30px;
  height: 30px;
  background: #609285;
  color: white;
  font-size: 14px;
  border-radius: 50%;
  line-height: 28px;
  border: 2px solid #522b52;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.user-icon .initials {
  text-transform: uppercase;
}

.modal {
  position: fixed;
  z-index: 100;
  max-width: 1200px;
  min-width: 400px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  top: unset;
  max-height: 80vh;
  overflow: auto;
  padding: 0 20px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

@media (max-width: 1400px) {
  .modal {
    max-width: 80%;
  }
}

@media (max-width: 500px) {
  .modal {
    min-width: 80%;
  }
}

.modal ::-webkit-scrollbar {
  width: 11px;
}

.modal ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #522b52;
  border-radius: 5px;
}

.modal ::-webkit-scrollbar-thumb {
  background: #784d78;
  border-radius: 5px;
}

.modal ::-webkit-scrollbar-thumb:hover {
  background: #b3a0b3;
}

.modal .user-card {
  min-width: auto;
  border: none;
  margin: 0 auto;
  padding: 0;
}

.modal .user-card .title {
  display: none;
}

.modal-header {
  width: 100%;
  padding: 1rem 0.5rem;
  color: white;
  position: relative;
}

.modal-header h2 {
  margin: 0.5rem;
  color: #405852;
}

@media (max-width: 700px) {
  .modal-header h2 {
    font-size: 25px;
    line-height: 46px;
  }
}

.modal-content {
  padding: 1rem 0.5rem;
}

.modal-footer {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 25px;
  width: 25px;
}

.close-modal:hover {
  cursor: pointer;
  opacity: 0.8;
}

.account-sidebar {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  margin: 100px 10px;
  border: 2px solid #609285;
  max-width: 220px;
  overflow: hidden;
}

@media (max-width: 500px) {
  .account-sidebar {
    max-width: none;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 100px 5px 20px;
    max-width: 90vw;
  }
}

.account-sidebar .icon {
  width: 75px;
  height: 75px;
  font-size: 40px;
  background-color: #609285;
  color: white;
  align-self: center;
  border-radius: 50%;
  padding: 10px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .account-sidebar .icon {
    display: none;
  }
}

.account-sidebar .item {
  padding: 20px;
  width: 100%;
  display: flex;
  color: #522b52;
  text-decoration: none;
}

@media (max-width: 500px) {
  .account-sidebar .item {
    justify-content: center;
    width: 50%;
    text-align: center;
  }
}

.account-sidebar .item:visited {
  color: #522b52;
}

.account-sidebar .item.name {
  font-weight: bold;
  overflow-wrap: anywhere;
}

@media (max-width: 500px) {
  .account-sidebar .item.name {
    display: none;
  }
}

.account-sidebar .item.active {
  background-color: #dceae7;
}

.account-sidebar .selected {
  background-color: #dceae7;
}

.account {
  display: flex;
  min-height: 900px;
}

@media (max-width: 500px) {
  .account {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .account .user-card {
    margin-top: 10px;
  }
}

.authenticate-container {
  min-height: 100vh;
  padding: 30px 50px 0 50px;
}

@media (max-width: 700px) {
  .authenticate-container {
    padding: 30px 0 0 0;
  }
}

.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.authentication .title {
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.authentication .title p {
  width: 330px;
  max-width: 100%;
}

.authentication form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.authentication form a {
  text-align: right;
}

.forgot-pwd {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #609285;
}

@media (max-width: 700px) {
  .authenticate-container {
    width: 100%;
  }
}

.switch {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  .switch {
    flex-direction: column;
  }
}

.reset-pwd .btn {
  margin-top: 10px;
}

.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: white;
  border-radius: 15px;
  min-width: 500px;
  border: 2px solid #609285;
  margin: 100px auto;
  max-width: 60vw;
  min-width: 60vw;
}

@media (max-width: 500px) {
  .user-card {
    max-width: 90vw;
  }
}

.user-card .title {
  border-bottom: 3px solid #dceae7;
  width: 100%;
  justify-content: flex-start;
}

.user-card .title h2 {
  border-bottom: none;
  color: #806a80;
  font-size: 41px;
  line-height: 46px;
  font-family: 'Sansita', sans-serif;
}

.user-card p {
  color: #405852;
}

.user-card .blurb {
  text-align: left;
}

.plan {
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  align-items: center;
}

.plan h2 {
  color: #a8cac2;
  font-size: 50px;
  line-height: 40px;
  border: none;
}

.plan h3 {
  font-family: 'Gabriola';
  color: #522b52;
  font-size: 50px;
  line-height: 55px;
  margin: 0;
}

.plan .initial-select {
  display: flex;
  justify-content: center;
}

.plan .initial-select .container {
  background: white;
  padding: 30px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  border: 2px solid #609285;
  margin-right: 30px;
  padding: 16px 30px;
  max-width: 230px;
  text-align: center;
  min-height: 85px;
}

.plan .initial-select .container.save {
  margin-left: 30px;
}

.plan .initial-select .container.selected {
  box-shadow: 4px 4px 39px #0dc795;
}

.plan .plan-select {
  display: flex;
}

.plan .plan-select .plan-container {
  background: white;
  padding: 30px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  border: 2px solid #609285;
  width: 465px;
  max-width: 465px;
}

.plan .plan-select .plan-container .price {
  font-size: 60px;
  line-height: 50px;
}

.plan .plan-select .plan-container .per {
  font-size: 20px;
  margin: 0 0 10px;
}

.plan .plan-select .plan-container .description {
  color: #609285;
  font-size: 18px;
  text-align: left;
  margin: 0;
}

.plan .account-quote {
  display: flex;
}

.plan .account-quote .quantity, .plan .account-quote .price-quote {
  font-family: 'Gabriola';
  font-size: 30px;
  color: #dceae7;
  margin: 20px 20px 10px;
}

.plan .account-quote .quantity .form-control, .plan .account-quote .price-quote .form-control {
  margin-top: 0;
  max-width: 150px;
  margin: auto;
}

.plan .account-quote .quantity .price, .plan .account-quote .price-quote .price {
  font-family: 'Gabriola';
  color: #a8cac2;
  font-size: 75px;
  line-height: 55px;
  font-weight: bold;
  margin: 0;
}

.main-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #f1f0f0;
  min-height: 100vh;
  width: 60%;
  padding-top: 105px;
  background-color: white;
}

@media (max-width: 1400px) {
  .main-container {
    width: 80%;
  }
}

@media (max-width: 850px) {
  .main-container {
    width: 100%;
  }
}

.main-container.premade-ws {
  max-width: 1300px;
  width: 80%;
}

@media (max-width: 850px) {
  .main-container.premade-ws {
    width: 100%;
  }
}

.main-container.premade-ws .content {
  width: 100%;
}

.main-container.premade-ws .title-container {
  width: 100%;
}

@media (max-width: 850px) {
  .main-container.premade-ws .title-container {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 7px;
  }
}

@media (max-width: 850px) {
  .main-container.premade-ws.premade-ws-selection h1 {
    padding: 0 0 33px;
  }
}

.main-container.premade-ws .prob-style-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px;
}

@media (max-width: 850px) {
  .main-container.premade-ws .prob-style-container {
    flex-wrap: wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (max-width: 850px) {
  .main-container.premade-ws .prob-style-container div:first-child {
    width: 100%;
  }
}

.main-container.premade-ws .prob-style-container .prob-style {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.main-container.premade-ws .prob-style-container .prob-style div {
  margin-right: 10px;
}

.main-container.premade-ws .prob-style-container .prob-style.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00968840;
  border-radius: 5px;
  border: 1px solid #2c3c2c3d;
}

.main-container.premade-ws .prob-style-container .prob-style:not(.active) .main-container.premade-ws .prob-style-container .prob-style:hover {
  opacity: 0.8;
}

.main-container .title-container {
  display: flex;
  padding: 0 20px 10px;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #dceae7;
}

@media (max-width: 450px) {
  .main-container .title-container {
    padding: 0;
  }
}

.main-container .title-container .back-btn {
  outline: none;
  border: none;
  margin-right: 20px;
  background: transparent;
  width: 40px;
}

.main-container .title-container .back-btn:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .main-container .title-container .back-btn {
    margin-right: 0;
    width: 25px;
  }
}

.main-container .title-container .back-arrow {
  height: 40px;
  width: 40px;
  background-color: white;
  margin: 0px 10px 0px 0px;
}

@media (max-width: 600px) {
  .main-container .title-container .back-arrow {
    height: 25px;
    width: 25px;
    margin: 0;
  }
}

.main-container .title-container .content {
  width: 100%;
}

.main-container .title-container .activity-label {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  align-self: flex-end;
  align-items: center;
  margin-left: auto;
}

.main-container .title-container .activity-label .activity-title {
  display: flex;
  font-size: 18px;
  color: #381638;
  font-weight: 300;
  align-items: center;
}

@media (max-width: 450px) {
  .main-container .title-container .activity-label .activity-title {
    font-size: 16px;
  }
}

.main-container .title-container .activity-label .price {
  font-size: 15px;
  line-height: 12px;
}

.main-container .title-container .activity-label .edit-button {
  display: flex;
  transition: opacity 0.5s;
  border: none;
  width: 20px;
  height: 25px;
  margin: 0 0 0 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  bottom: calc(0% + 4px);
  right: 0;
  background-image: url(/static/media/icon-edit.6baab029.svg);
  right: 55px;
}

.main-container .title-container .activity-label .edit-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.main-container .customize-title {
  font-size: 25px;
  font-family: 'Sriracha';
  color: #522b52;
  margin: 0;
  text-align: left;
}

@media (max-width: 700px) {
  .main-container .customize-title {
    font-size: 18px;
  }
}

.support-ticket {
  display: flex;
  flex-direction: column;
  min-height: 780px;
  top: unset;
  max-height: 80vh;
  overflow: auto;
}

.support-ticket .main-section {
  padding: 0 50px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 900px) {
  .support-ticket .main-section {
    padding: 0 20px;
  }
}

.support-ticket p {
  margin: 0;
  padding: 0;
  text-align: left;
}

.support-ticket .MuiFormControl-root {
  max-width: 80%;
  width: 700px;
  margin: 30px 0;
}

.support-ticket .support-message {
  width: 700px;
  max-width: 80%;
}

.support-ticket .error {
  width: 700px;
  max-width: 80%;
}

.user-selection {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 40%;
  min-width: 500px;
}

@media (max-width: 500px) {
  .user-selection {
    min-width: auto;
    width: 90%;
    margin-top: 30px;
  }
}

.display-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 465px;
  max-width: 95%;
}

.display-us .us-table {
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 75vh;
}

@media (max-width: 800px) {
  .display-us .us-table {
    max-height: unset;
  }
}

.display-us .us-table .table-wrap {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
}

.display-us .us-table .main-title {
  background-color: #405852;
  color: white;
  text-align: center;
  font-weight: 500;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  letter-spacing: 1px;
}

@media (max-width: 600px) {
  .display-us .us-table .main-title {
    font-size: 20px;
  }
}

.display-us .us-table .sub-titles {
  display: flex;
  width: 100%;
}

.display-us .us-table .sub-titles .title {
  background-color: #609285;
  color: white;
  border: 1px solid #7c7c7c;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.display-us .us-table .sub-titles .title:first-child {
  border-right: none;
}

.display-us .us-table .sub-titles .title:last-child {
  border-left: none;
}

@media (max-width: 550px) {
  .display-us .us-table .sub-titles .title.mobile-hidden {
    display: none;
  }
}

.display-us .us-table .trash-button {
  display: flex;
  opacity: 0;
  transition: opacity 0.5s;
  border: none;
  background-image: url(/static/media/icon-trash.435e369f.svg);
  width: 20px;
  height: 25px;
  margin: 0;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  background-color: transparent;
  bottom: calc(0% + 4px);
  right: 0;
}

.display-us .us-table .trash-button.active {
  width: 20px;
  height: 28px;
  background-image: url(/static/media/icon-trash-open.d50ab34a.svg);
}

@media (hover: none) {
  .display-us .us-table .trash-button {
    opacity: 1;
  }
}

.display-us .us-table .trash-button:hover {
  cursor: pointer;
}

.display-us .us-table .edit-button {
  display: flex;
  opacity: 0;
  transition: opacity 0.5s;
  background-image: url(/static/media/icon-edit.6baab029.svg);
  position: absolute;
  right: 55px;
}

@media (hover: none) {
  .display-us .us-table .edit-button {
    opacity: 1;
  }
}

.display-us .us-table .edit-button.white {
  background-image: url(/static/media/icon-edit-white.a8cd3774.svg);
}

.display-us .us-table .edit-button:hover {
  background-image: url(/static/media/icon-edit.6baab029.svg);
  width: 20px;
  height: 25px;
}

.display-us .us-table .edit-button:hover.white {
  background-image: url(/static/media/icon-edit-white.a8cd3774.svg);
}

.display-us .us-table .row-group {
  display: flex;
  flex-direction: column;
}

.display-us .us-table .row {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #d3d3d3;
  border-top: none;
}

.display-us .us-table .row .item {
  background-color: white;
  min-height: 36px;
  padding: 3px;
  transition: background-color 0.5s;
}

.display-us .us-table .row .item.edit {
  background-color: #dceae7;
}

@media (max-width: 550px) {
  .display-us .us-table .row .item.mobile-hidden {
    display: none;
  }
}

.display-us .us-table .row:hover .item {
  background-color: #d9d1d9;
  border-color: #a7a7a7 !important;
  transition: border 0.3s ease;
}

.display-us .us-table .row:hover .trash-button {
  opacity: 1;
}

.display-us .us-table .row:hover .edit-button {
  opacity: 1;
}

.display-us .us-table .concept {
  min-width: 160px;
  max-width: 160px;
}

@media (max-width: 550px) {
  .display-us .us-table .concept {
    min-width: calc(100% - 47px) !important;
    max-width: calc(100% - 47px) !important;
    flex-grow: 1;
  }
}

.display-us .us-table .specify {
  width: 100%;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
}

.display-us .us-table .specify ul {
  margin: 0;
  padding-left: 23px;
}

.display-us .us-table .quantity {
  min-width: 40px;
}

@media (max-width: 550px) {
  .display-us .us-table .quantity {
    min-width: 47px;
  }
}

.display-us .us-table .table-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-top: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  flex-wrap: wrap;
}

.display-us .us-table .table-footer .edit-button {
  opacity: 0;
  right: -25px;
  top: 8px;
}

@media (hover: none) {
  .display-us .us-table .table-footer .edit-button {
    opacity: 1;
  }
}

.display-us .us-table .table-footer .activity {
  display: flex;
  align-items: center;
  padding: 0 13px 0 0;
  min-width: 260px;
}

.display-us .us-table .table-footer .activity:hover .edit-button {
  opacity: 1;
}

.display-us .us-table .table-footer .activity .title {
  max-width: 90px;
}

.display-us .us-table .table-footer .questions {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.display-us .us-table .table-footer .title {
  text-align: right;
  margin-right: 10px;
}

.display-us .us-table .table-footer .sub {
  padding: 3px;
}

.display-us .us-table .table-footer .sub.sub-activity {
  position: relative;
}

.display-us .us-table .table-footer.title-footer .activity {
  align-items: flex-start;
}

.display-us .us-table .table-footer.title-footer .sub-activity {
  font-size: 16px;
  font-weight: 500;
}

.display-us .us-table .table-footer.title-footer .sub-activity .edit-button {
  top: 5px;
}

.display-us .btn-container {
  margin-top: 40px;
  padding-bottom: 30px;
}

.display-us .btn-container .btn {
  width: auto;
}

.display-us .warning {
  color: #e98b85;
}

.display-us .signup-freews {
  color: white;
  padding-bottom: '20px';
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.display-us .signup-freews:hover {
  opacity: 0.7;
}

.display-us .directions-link {
  color: white;
  margin-top: 10px;
  align-self: flex-start;
}

.display-us .directions-link img {
  -webkit-filter: invert(72%) sepia(60%) saturate(106%) hue-rotate(95deg) brightness(108%) contrast(78%);
          filter: invert(72%) sepia(60%) saturate(106%) hue-rotate(95deg) brightness(108%) contrast(78%);
}

.display-us .directions-link:hover {
  color: #dceae7;
}

.switch-premade {
  position: absolute;
  right: 20px;
  bottom: -10px;
}

.switch-premade button {
  color: #806a80;
  background: transparent;
  border: none;
  font-size: 12px;
  font-family: arial, sans-serif;
  font-weight: 500;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.switch-premade button:hover {
  opacity: 0.7;
}

@media (max-width: 450px) {
  .switch-premade {
    bottom: 0;
  }
}

@media (max-width: 850px) {
  .premade-ws:not(.activity-selections) .switch-premade {
    bottom: 20px;
  }
}

@media (max-width: 500px) {
  .premade-ws:not(.activity-selections) .switch-premade {
    right: 0;
  }
}

.general-customize {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 700px) {
  .general-customize {
    display: flex;
    flex-direction: column;
  }
}

.general-customize .row1-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
}

@media (max-width: 500px) {
  .general-customize .row1-container {
    flex-direction: column;
  }
}

.general-customize .quantity-label {
  font-size: 14px;
  color: #522b52;
}

.general-customize .quantity-input {
  font-size: 20px;
  border-color: #522b52;
  border-radius: 5px;
  /* box-shadow: 2px 2px 7px 1px rgb(23, 76, 92);; */
  text-align: center;
}

.general-customize .MuiFilledInput-input {
  padding: 33px 12px 10px;
}

.concept-customize {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media (max-width: 1450px) {
  .concept-customize {
    background-color: white;
    width: 80%;
    align-items: center;
  }
}

@media (max-width: 850px) {
  .concept-customize {
    width: 100%;
  }
}

.concept-customize .radio-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: white;
  align-items: flex-start;
  justify-content: center;
  min-height: 440px;
}

@media (max-width: 1150px) {
  .concept-customize .radio-container {
    width: 60%;
    flex-direction: column;
    flex-wrap: wrap;
    height: 50%;
    justify-content: flex-start;
  }
}

@media (max-width: 700px) {
  .concept-customize .radio-container {
    width: 70%;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    min-height: auto;
    padding-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .concept-customize .radio-container {
    width: 100%;
  }
}

.concept-customize .dynamic-container {
  display: flex;
  min-width: 270px;
}

.concept-customize .dynamic-container .dynamic-sub-container {
  display: flex;
}

.concept-customize .dynamic-container .eq-ex {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
}

.concept-customize .dynamic-container .eq-ex.eq {
  font-size: 33px;
  width: 132px;
}

.concept-customize .dynamic-container .eq-ex.explain {
  font-size: 12px;
  width: 106px;
}

.concept-customize .dynamic-container.all-style .dynamic-sub-container {
  flex-direction: column;
}

.concept-customize .dynamic-container.all-style .eq-ex {
  margin: 0;
}

.concept-customize .dynamic-container.all-style .explain {
  margin-left: 10px;
}

.concept-customize .customize-title-container {
  margin: 0 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 700px) {
  .concept-customize .customize-title-container {
    margin: 0;
  }
}

.concept-customize .customize-title-container .multi-step {
  position: relative;
  display: flex;
  align-items: center;
}

.concept-customize .customize-title-container .multi-step div {
  max-width: 240px;
  width: auto;
}

.concept-customize .sub-container.placeholder {
  min-width: 250px;
  min-height: 410px;
}

@media (max-width: 600px) {
  .concept-customize .sub-container.placeholder {
    min-height: unset;
  }
}

.concept-customize .sub-container .fract-include {
  margin-top: 23px;
}

.checkbox-app {
  display: flex;
  position: relative;
  min-width: 150px;
  align-items: center;
}

.specify-info {
  width: 20px;
  height: 20px;
}

.specify-info img {
  width: 20px;
  height: 20px;
}

.specify-info .popover {
  left: 0;
  width: 235px;
}

.question-level-fieldset .one-step.MuiFormControlLabel-root {
  margin-left: 0;
}

.premade-title {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 850px) {
  .premade-title {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 10px;
  }
}

.popover-container {
  margin-left: 10px;
  font-weight: normal;
}

.popover-container .popover {
  left: 30px;
  font-size: 14px;
  font-family: arial, sans-serif;
}

.bold2 {
  color: #522b52;
  font-family: 'Exo' sans-serif;
}

.title-text {
  font-family: 'Poppins';
  font-size: 1rem;
}

.concept-topic {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  background-color: white;
  width: 100%;
  color: #290f0f;
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.concept-topic:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  border-radius: 5px;
  background: #806a80;
  width: 100%;
}

@media (max-width: 750px) {
  .concept-topic {
    font-size: 1.2rem;
  }
}

.buttonGroup {
  display: flex;
  margin: 10px;
  width: 30%;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .buttonGroup {
    width: 45%;
  }
}

@media (max-width: 800px) {
  .buttonGroup {
    width: 220px;
  }
}

@media (max-width: 800px) {
  .premade-ws .buttonGroup {
    width: 290px;
  }
}

.ws-links .buttonGroup {
  width: 45%;
}

@media (max-width: 700px) {
  .ws-links .buttonGroup {
    width: 100%;
  }
}

.ws-links .buttonGroup.large {
  width: 100%;
  max-width: 783px;
}

.ws-links .buttonGroup .concept-topic {
  font-size: 16px;
}

.ws-links .buttonGroup .sec {
  height: 1px;
  width: 100%;
  background: #dceae7;
  margin: 7px 0;
}

.ws-links .buttonGroup .sec-title {
  color: #609285;
  font-size: 14px;
  padding: 10px 0 0 22px;
  text-align: center;
  margin-bottom: -9px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.concept-button-container {
  display: flex;
  width: 100%;
  border-radius: 10px;
  flex-wrap: wrap;
  flex-direction: column;
  text-decoration: none;
}

.concept-button {
  position: relative;
  border: none;
  width: 100%;
  display: inline-block;
  text-align: left;
  font-weight: bold;
  font-family: arial, sans-serif;
  font-size: 13px;
  background-color: white;
  padding: 10px;
  -webkit-text-decoration-color: white;
          text-decoration-color: white;
  transition: background-color 0.3s ease-out;
}

.concept-button:hover {
  cursor: pointer;
  background-color: #dceae7;
}

.concept-button.disabled {
  opacity: 0.7;
}

.concept-button.disabled:hover {
  cursor: default;
  background-color: white;
}

.dropdown-content a:hover {
  background-color: #eec0c0;
  left: 20px;
}

.concept-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 650px) {
  .concept-container {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .concept-selection-container p {
    font-size: 1.8rem;
    border-bottom: 5px solid #609285;
    width: auto;
    /* padding: 0px 10% 0px 10%; */
    margin: 10px 5vw 10px 5vw;
  }
}

.display-assignment-container {
  display: flex;
  align-items: center;
  margin: 60px 0px 0px 0px;
  padding: 20px;
  flex-direction: row;
}

@media (max-width: 500px) {
  .display-assignment-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1350px) {
  .display-assignment-container .btn {
    font-size: 16px;
    padding: 10px 25px;
  }
}

.react-pdf__Page__canvas {
  max-width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}

.hide-btn {
  display: none;
}

.save-ws {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: #405852;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: none;
  margin: 0 0 0 auto;
  padding: 12px 10px 16px;
  box-shadow: 2px 2px 5px #3f2828;
}

.save-ws.save-btn:hover {
  cursor: pointer;
  background: #405852ab;
}

.save-ws.save-btn:hover .popover {
  opacity: 1;
  z-index: 6;
}

.save-ws img {
  width: 30px;
}

.save-ws.saved {
  background: #405852ab;
  box-shadow: none;
}

.save-ws .popover {
  right: 0;
  left: unset;
  top: calc(100% + 5px);
}

.pdf-viewer-container {
  display: flex;
  position: relative;
  background-color: white;
  justify-items: center;
  width: 910px;
  max-width: 80vw;
  padding: 30px;
  margin: auto;
}

@media (max-width: 1000px) {
  .pdf-viewer-container {
    max-width: calc(100vw - 40px);
    padding: 10px;
  }
}

@media (max-width: 550px) {
  .pdf-viewer-container {
    max-width: 93vw;
    padding: 15px;
  }
}

.pdf-viewer-container .pdf-viewer {
  width: calc(100% - 6px);
}

.pdf-viewer-container .pdf-viewer.purchasing, .pdf-viewer-container .pdf-viewer.draft {
  display: flex;
}

.report-problem {
  text-align: right;
  color: #bfaebf;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  transition: opacity 0.3s;
}

.report-problem .report-btn {
  display: flex;
  align-items: center;
}

.report-problem .report-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}

.report-problem .menu-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.ws-warning {
  position: relative;
  display: flex;
  align-items: center;
  width: 122px;
  color: white;
  margin-bottom: 7px;
}

.ws-warning img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  -webkit-filter: invert(0%) sepia(3%) saturate(8%) hue-rotate(5deg) brightness(1002%) contrast(98%);
          filter: invert(0%) sepia(3%) saturate(8%) hue-rotate(5deg) brightness(1002%) contrast(98%);
}

.ws-warning:hover {
  cursor: default;
}

.ws-warning .popover {
  left: -15px;
  top: 35px;
  width: 725px;
  background: white;
  color: #522b52;
}

.ws-warning .popover p {
  margin: 20px;
  width: auto;
  text-align: left;
}

.final-selections {
  min-width: 200px;
}

@media (max-width: 1450px) {
  .final-selections {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .final-selections {
    margin: 0;
    margin-top: 60px;
    width: 90%;
  }
}

@media (max-width: 600px) {
  .final-selections {
    margin-top: 0;
  }
}

.final-selections h1 {
  padding: 0px 0px 0px 10px;
  width: 800px;
}

.final-selections .ws-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 550px;
  min-width: 300px;
  margin-top: 40px;
}

@media (max-width: 1000px) {
  .final-selections .ws-title-container {
    margin: 40px 10% 0px 10%;
  }
}

@media (max-width: 700px) {
  .final-selections .ws-title-container {
    width: 90%;
    min-width: auto;
  }
}

.final-selections .ws-title-container .doc-title-input {
  border-radius: 5px;
  font-size: 20px;
  margin: 2px 0px 10px 0px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 1000px) {
  .final-selections .ws-title-container .doc-title-input {
    font-size: 15px;
  }
}

.final-selections .ws-style-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  width: 83%;
}

@media (max-width: 700px) {
  .final-selections .ws-style-container {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 500px) {
  .final-selections .ws-style-container {
    width: 100%;
  }
}

.final-selections .ws-style-container .radio-group {
  display: flex;
  flex-direction: row !important;
  width: 600px;
  justify-content: center;
}

.final-selections .ws-style-container .radio-group .activity-group {
  display: flex;
  flex-direction: column;
}

.final-selections .ws-style-container .details {
  display: flex;
  height: 230px;
}

@media (max-width: 700px) {
  .final-selections .ws-style-container .details {
    margin-left: 0;
    height: auto;
  }
}

.final-selections .ws-style-container .details .general-checkbox {
  display: flex;
  flex-direction: column;
}

.final-selections .ws-style-container .details .general-checkbox .tables-columns-warning {
  padding-bottom: 10px;
}

.final-selections .ws-style-container .details .checkbox {
  font-family: inherit;
  font-size: inherit;
}

.final-selections .header-title {
  font-size: 20px;
  padding-top: 20px;
}

.user-ws {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1000px) {
  .user-ws {
    width: 80vw;
  }
}

@media (max-width: 700px) {
  .user-ws {
    width: 90vw;
    padding: 0px;
  }
}

.user-ws .title {
  font-family: 'Sansita', sans-serif;
  color: #609285;
  font-size: 40px;
  text-align: center;
  margin: 20px 193px 20px 20px;
}

@media (max-width: 1000px) {
  .user-ws .title {
    margin: 20px 100px 20px 20px;
  }
}

@media (max-width: 700px) {
  .user-ws .title {
    margin: 70px 40px 20px 20px;
    font-size: 35px;
  }
}

@media (max-width: 500px) {
  .user-ws .title {
    font-size: 29px;
  }
}

.user-ws .top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

@media (max-width: 600px) {
  .user-ws .top-container {
    flex-direction: column;
  }
}

.user-ws .top-container .sort-by {
  position: relative;
  min-width: 153px;
}

.user-ws .top-container .sort-by .sort {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-ws .top-container .sort-by .sort .triangle {
  border: 7px solid transparent;
  border-top-color: black;
  border-bottom: 0;
  margin-left: 10px;
}

.user-ws .top-container .sort-by .sort-menu {
  opacity: 0;
  background: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -125px;
  z-index: 10;
  background-color: white;
  box-shadow: 2px 3px 5px #c0bebe;
  border-radius: 3px;
  right: 0;
  transition: opacity 0.3s ease-out;
  overflow: hidden;
  pointer-events: none;
}

.user-ws .top-container .sort-by .sort-menu.active {
  opacity: 1;
  pointer-events: all;
}

.user-ws .top-container .sort-by .sort-menu .menu-item {
  cursor: pointer;
  padding: 10px;
  border-top: 1px solid rgba(128, 0, 128, 0.205);
}

.user-ws .top-container .sort-by .sort-menu .menu-item:last-of-type {
  border-bottom: 1px solid rgba(128, 0, 128, 0.205);
}

.user-ws .page-turner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 650px;
  max-width: 80vw;
  color: white;
  padding: 0;
}

@media (max-width: 450px) {
  .user-ws .page-turner {
    flex-direction: column;
  }
}

.user-ws .page-turner .first-last {
  display: flex;
}

@media (max-width: 450px) {
  .user-ws .page-turner .first-last {
    justify-content: space-between;
    color: #b3a0b3;
    width: 100%;
    width: 85vw;
  }
}

.user-ws .page-turner .last, .user-ws .page-turner .first {
  cursor: pointer;
  color: #b3a0b3;
}

.user-ws .page-turner p {
  padding: 10px 0;
}

.user-ws .page-turner .button-container {
  display: flex;
  align-items: center;
}

.user-ws .page-turner .button-container button {
  background: transparent;
  color: #522b52;
  border: none;
}

.user-ws .page-turner .button-container button:hover {
  cursor: pointer;
}

.user-ws .page-turner .button-container .num-btn {
  margin: 0 5px;
  height: 24px;
  width: 20px;
  font-size: 14px;
  letter-spacing: 4px;
  color: #522b52;
  font-family: 'Acme';
  border-radius: 5px;
  border: 1px solid #b3a0b3;
  transition: all 0.3s;
}

.user-ws .page-turner .button-container .num-btn:hover {
  background-color: #b3a0b3;
  color: white;
}

@media (max-width: 600px) {
  .user-ws .page-turner .button-container .num-btn {
    font-size: 12px;
    margin: 0 2px;
  }
}

.user-ws .page-turner .button-container .num-btn.active {
  background-color: #522b52;
  border-radius: 5px;
  color: white;
}

.user-ws .page-turner .button-container .arrow {
  font-size: 46px;
  line-height: 50px;
  margin: 0 20px 7px 20px;
  font-family: 'Acme';
}

@media (max-width: 600px) {
  .user-ws .page-turner .button-container .arrow {
    margin: 0;
    font-size: 25px;
  }
}

.user-ws .page-turner .button-container .ellipsis {
  color: #522b52;
  font-family: 'Acme';
  align-self: flex-end;
  margin: 0 5px;
  letter-spacing: 3px;
  padding-bottom: 10px;
}

@media (min-width: 450px) {
  .user-ws .desktop-hidden {
    display: none;
  }
}

@media (max-width: 450px) {
  .user-ws .mobile-hidden {
    display: none;
  }
}

.ws-list {
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  width: 90%;
  max-width: 80rem;
}

.ws-item {
  margin: 0px 50px;
  text-align: start;
}

@media (max-width: 1000px) {
  .ws-item {
    margin: 0;
  }
}

.ws-item .content {
  padding: 0.2rem;
  box-shadow: none;
  border-bottom: 1px solid #89548942;
  border-radius: 0;
}

.ws-item .content:hover {
  cursor: pointer;
}

.ws-data {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.ws-data p {
  margin: 5px;
  text-align: left;
}

.ws-data .general-ws-info {
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
}

@media (max-width: 700px) {
  .ws-data .general-ws-info {
    justify-content: space-between;
  }
}

@media (max-width: 500px) {
  .ws-data .general-ws-info {
    flex-wrap: wrap;
  }
}

.ws-data .general-ws-info .main-title {
  width: 65%;
  font-size: 1.2rem;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .ws-data .general-ws-info .main-title {
    width: 100%;
  }
}

.ws-data .general-ws-info .ws-title {
  color: #522b52;
  margin: 0 5px;
  font-family: 'DidactGothic';
  line-height: 1em;
  font-size: 18px;
  font-weight: 600;
}

.ws-data .general-ws-info .sub-title {
  font-size: 1rem;
  color: #609285;
  margin-top: 0;
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-weight: normal;
}

.ws-data .general-ws-info .total-Q {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: grey;
  font-size: 10px;
  flex-grow: 1;
  width: 30%;
}

@media (max-width: 700px) {
  .ws-data .general-ws-info .total-Q {
    font-size: 13px;
  }
  .ws-data .general-ws-info .total-Q .questions {
    display: none;
  }
}

.ws-data .general-ws-info .total-Q .quantity {
  font-family: 'Acme';
  font-size: 15px;
}

.ws-data .general-ws-info .created-at {
  color: grey;
  font-size: 10px;
  width: 22%;
  min-width: 90px;
  margin-right: 0px;
  /* margin-block-end: 0; */
  margin: 0;
  text-align: left;
}

.ws-data .general-ws-info .dropdown {
  border: 0px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  width: 23px;
  height: 27px;
  margin-right: 0;
  padding: 20px 10px;
  cursor: pointer;
}

.ws-data .sub-title-container, .ws-data .concept-container {
  display: flex;
  flex-wrap: nowrap;
  width: 775px;
  max-width: 100%;
  margin: auto;
}

.ws-data .sub-title-container .quantity, .ws-data .concept-container .quantity {
  width: 75px;
  min-width: 75px;
  text-align: center;
}

.ws-data .sub-title-container .specify, .ws-data .concept-container .specify {
  width: 100%;
}

.ws-data .sub-title-container .concept, .ws-data .concept-container .concept {
  width: 135px;
  min-width: 135px;
}

.ws-data .concept-container {
  background-color: white;
  font-family: 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 0.3px;
}

.ws-data .concept-container:nth-child(odd) {
  background-color: #60928529;
}

@media (max-width: 650px) {
  .ws-data .concept-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ws-data .concept-container .concept {
    font-weight: bold;
    width: 100%;
  }
}

.ws-data .concept-container ul {
  display: flex;
  flex-wrap: wrap;
}

.ws-data .concept-container ul li {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 37px;
}

.ws-data .sub-title-container {
  background-color: #405852;
  color: white;
}

@media (max-width: 650px) {
  .ws-data .sub-title-container {
    display: none;
  }
}

.ws-data .content-container {
  display: flex;
  flex-direction: column;
}

.ws-data .expand-ws-data {
  background-color: white;
  border: none;
  outline: none;
}

.ws-data .expand-ws-data:hover {
  cursor: pointer;
}

.new-ws-modal {
  min-height: 400px;
}

.ws-title-container.new-version {
  width: 350px;
  margin: auto;
  max-width: 100%;
}

.ws-title-container.new-version .doc-title-input {
  width: 350px;
  max-width: 100%;
}

.selection-table {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.selection-table.active {
  max-height: 1000px;
  max-width: 100%;
  transition: max-height 0.6s ease-out;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.menu-link {
  display: flex;
  align-items: center;
}

.menu-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.menu-icon.download {
  height: 20px;
}

.activity-selections {
  min-width: 200px;
}

@media (max-width: 1450px) {
  .activity-selections {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .activity-selections {
    margin: 0;
    margin-top: 60px;
    width: 90%;
  }
}

@media (max-width: 700px) {
  .activity-selections {
    margin-top: 0;
  }
}

.activity-selections .ws-title-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 400px;
  min-width: 300px;
}

@media (max-width: 1000px) {
  .activity-selections .ws-title-container {
    width: 70%;
    margin: 0px 10% 0px 10%;
  }
}

@media (max-width: 700px) {
  .activity-selections .ws-title-container {
    width: 100%;
    min-width: auto;
  }
}

.activity-selections .ws-title-container .doc-title-input {
  border-radius: 5px;
  font-size: 20px;
  margin: 2px 0px 10px 0px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 1000px) {
  .activity-selections .ws-title-container .doc-title-input {
    font-size: 15px;
  }
}

.activity-selections .ws-style-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.activity-selections .ws-style-container .details {
  display: flex;
  padding-bottom: 20px;
}

.activity-selections .ws-style-container .details .general-checkbox {
  display: flex;
  flex-direction: column;
}

.activity-selections .ws-style-container .details .checkbox {
  font-family: inherit;
  font-size: inherit;
}

.activity-selections .ws-style-container .warning {
  color: #609285;
  font-size: 14px;
}

.activity-selections .customize-title {
  display: flex;
  align-items: center;
  width: 200px;
  text-align: center;
}

.activity-selections .customize-title img {
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.activity-selections .activity-btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.activity-selections .activity-btn-group .activity-btn {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 10px;
  min-width: 166px;
  min-height: 199px;
}

@media (max-width: 700px) {
  .activity-selections .activity-btn-group .activity-btn {
    min-width: auto;
  }
}

@media (max-width: 600px) {
  .activity-selections .activity-btn-group .activity-btn {
    min-height: 145px;
  }
}

.activity-selections .activity-btn-group .activity-btn:hover {
  cursor: pointer;
}

.activity-selections .activity-btn-group .activity-btn:hover .overlay {
  opacity: 1;
  border: 2px solid #633d63;
}

.activity-selections .activity-btn-group .activity-btn:hover img {
  -webkit-filter: blur(0.5px);
          filter: blur(0.5px);
}

.activity-selections .activity-btn-group .activity-btn .overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 25px;
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  text-shadow: 0 1px 4px #381638;
  font-family: "Wendy One";
  background-color: rgba(180, 160, 180, 0.651);
  transition: opacity 0.5s;
  border-radius: 3px;
}

@media (max-width: 700px) {
  .activity-selections .activity-btn-group .activity-btn .overlay {
    font-size: 19px;
  }
}

.activity-selections .activity-btn-group .activity-btn .overlay .amt {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.7px;
  font-family: 'Sansita';
}

@media (max-width: 700px) {
  .activity-selections .activity-btn-group .activity-btn .overlay .amt {
    font-size: 16px;
  }
}

.activity-selections .activity-btn-group .activity-btn img {
  max-height: 200px;
  position: relative;
}

@media (max-width: 600px) {
  .activity-selections .activity-btn-group .activity-btn img {
    max-height: 145px;
  }
}

.activity-selections .activity-btn-group .activity-btn.active .overlay {
  opacity: 1;
  background-color: rgba(180, 143, 180, 0.651);
  box-shadow: 5px 5px 10px grey;
  border: 2px solid #633d63;
}

.activity-selections .activity-btn-group .activity-btn.active img {
  -webkit-filter: blur(0.5px);
          filter: blur(0.5px);
}

.activity-selections .activity-btn-group .bingo-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.activity-selections .activity-btn-group .sh-group {
  display: flex;
  flex-direction: column;
}

.activity-modal {
  min-height: 700px;
}

@media (max-width: 700px) {
  .activity-modal {
    max-width: 90vw;
    left: 5vw;
  }
}

.main-activity-modal .activity-group {
  padding: 10px 20px;
}

.main-activity-modal .activity-group .title-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main-activity-modal .activity-group .activity-icon {
  width: 40px;
  margin-right: 10px;
}

.main-activity-modal .activity-group .activity-title {
  display: flex;
  align-items: center;
  font-family: "Wendy One";
  color: #522b52;
  font-size: 30px;
  margin-right: 15px;
  cursor: pointer;
  width: auto;
}

.main-activity-modal .activity-group .description {
  font-size: 20px;
  font-family: "Tenali";
  height: 0;
  max-height: 0;
  overflow: hidden;
}

@media (max-width: 765px) {
  .main-activity-modal .activity-group .description {
    font-size: 16px;
  }
}

.main-activity-modal .activity-group .description.active {
  height: auto;
  max-height: 1000px;
}

.main-activity-modal .max-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
  border: 3px solid #a8cac2;
  background-color: #381638;
  font-family: "Tenali";
  font-size: 20px;
  line-height: 14px;
  color: white;
  padding: 3px 3px 0 3px;
}

.activity-wrapper {
  overflow: auto;
  max-height: calc(100vh - 363px);
  min-height: calc(100vh - 363px);
  padding: 0 0 0 50px;
}

@media (max-width: 700px) {
  .activity-wrapper {
    padding: 0 0 0 15px;
  }
}

.MuiTypography-body1 {
  max-height: 200px;
}

@media (max-width: 400px) {
  .MuiTypography-body1 {
    font-size: 0.9rem;
  }
}

.bingo-customize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 42px;
}

.directions-link {
  font-family: 'Tenali';
  font-size: 20px;
  margin-top: 10px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
}

.directions-link img {
  width: 20px;
  margin: 0 10px 0 10px;
}

.directions-link:hover {
  border-bottom: 2px solid #9ac5baa6;
  color: #405852;
}
/*# sourceMappingURL=main.css.map */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 45;
}

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  /* border: 1px solid #226672; */
  /* border-radius: 4px; */
  /* background: #226672; */
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button-pressed {
  box-shadow: inset 0px 0px 5px #c1c1c1 !important;
  outline: none !important;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  /* background: #277785;
  border-color: #277785; */
}






/* .button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
} */

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.card {
  position: relative;
  margin: 10px 0 0 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  z-index: 10;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-white .lds-dual-ring:after{
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-color: #ffffff #fff #ffffff transparent;
}
.small-white .lds-dual-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 7px;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
